import React from 'react';
import { navigate } from "gatsby"

export default function Certificados() {
  return (
    <section className="content-section about-section" id="certificados">
      <div className="container mt-lg-4">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="block-gradient__main">
              <h2 className="text-white mb-lg-5 font-size__l text-center">
                Somos especialistas en Adobe Commerce Magento 2
              </h2>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <p className="text-white mb-lg-5 font-size__m">
                    Implementar herramientas de este calibre puede suponer un gran ahorro en cuanto a gastos operativos y 
                    un aumento de ventas debido a su gran eficiencia pero, si no se realiza correctamente puede derivar 
                    en una fuente de errores y gastos de mantenimiento.
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="mb-5 text-center">
                    <p className="text-white mb-lg-5 font-size__m text-left">
                      En Beköme no queremos que eso ocurra y por eso solo trabajamos con herramientas que conocemos a la 
                      perfección.
                    </p>
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.7/v1569233073/bekome/magento_associate_developer_kitmg6.png"
                      alt="Magento Certified Associate Developer"
                      className="img-fluid"
                    />
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.7/v1569233073/bekome/magento_frontend_developer_cgc0pj.png"
                      style={{ margin: '0 4px 0 0' }}
                      alt="Magento Frontend Developer"
                      className="img-fluid"
                    />
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.5/v1569233073/bekome/magento_professional_developer_pjiatr.png"
                      style={{ margin: '0 8px 0 0' }}
                      alt="Magento Professional Developer"
                      className="img-fluid"
                    />
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.5/v1569233073/bekome/magento_solution_specialist_xklxri.png"
                      alt="Magento Solution Specialist"
                      className="img-fluid"
                    />
                  </div>
                  <div className='text-center'>
                    <button className='btn btn-primary btn-lg' onClick={() => {navigate("/nosotros/")}}>¿Porqué con nosotros?</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="96f705d6-340a-4a31-a476-3563ba228c38" data-share-badge-host="https://www.credly.com"></div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script>
    </section>
  );
}
