import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import Seo from '../components/Seo';
import Intro from '../components/Magento2/Intro';
import Calendly from '../components/Calendly';
import { navigate } from 'gatsby';
import ScrollToTop from '../components/ScrollToTop';
import Beneficios from '../components/Magento2/Beneficios';
import Gartner from '../components/Magento2/Gartner';
import Certificados from '../components/Magento2/Certificados';

class ProyectosMagentoEcommerce extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <Seo
          title="Expertos ecommerce Adobe Commerce Magento 2"
          description="Bekome es una agencia Ecommerce especializada en Adobe Commerce Magento 2. Desarrollamos tu proyecto a medida, 
          realizamos integraciones con tu ERP, PIM o CRM, y te damos todo el soporte y mantenimiento necesario."
          canonicalUrl="https://www.bekome.digital/proyectos-magento-ecommerce/"
        />

        <header className="masthead secondary magento d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">Adobe Commerce Magento 2</h1>
            <h2 className="mb-5">
              Potencia y escalabilidad al servicio de tu Ecommerce
            </h2>
            <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>Me interesa</button>
            <Scroll type="id" element="start" offset={0}>
              <a className="text-white vert-move" href="#start" title="Expertos Adobe Commerce Magento 2">
                <i className="icon-arrow-down"></i>
              </a>
            </Scroll>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />

        <Beneficios />

        <Gartner />

        <Certificados />

        <Calendly />

        <div className='container-fluid bg-grey'>
          <div className="container text-center text-white">
            <div className="row">
              <div className="col-12 text-secondary">
                <h4 className="font-size__l mt-5">
                  ¿Te gustaría acelerar el crecimiento de tu eCommerce?
                </h4>
                <p className='font-size__m'>
                  Te ayudaremos a sacar el máximo rendimiento a tu eCommerce ajustando sus opciones y funcionalidades a 
                  las necesidades reales de tu empresa.
                </p>
                <p className='font-size__m mb-5'>
                  Rellena este formulario para más información, contestamos en 24h laborables.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Contact />

        <Footer />

        <ScrollToTop />

      </LayoutEs>
    );
  }
}

export default ProyectosMagentoEcommerce;
