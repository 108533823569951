import React from 'react';

export default function Gartner() {
  return (
    <section
      className="content-section gartner-section"
      id="gartner"
    >
      <div className="container-fluid text-secondary">
        <div className='row'>
          <div className='col-12 col-lg-5 offset-lg-1 pl-5'>
            <h2 className="font-size__xl mb-5">
              Magento 2, líder en el mercado
            </h2>
            <p className='font-size__m'>
                Cuando tantas empresas confían en una misma solución para el desarrollo de su eCommerce, será por algo ¿no?
            </p>
            <p className='font-size__m'>
                Y es que, Magento, se ha sabido adaptar a las necesidades de sus clientes en cada momento y, 
                por este motivo, ha logrado mantenerse como referente en el sector de la venta online durante los últimos 
                15 años.
            </p>
          </div>
          <div className='offset-lg-1'></div>
          <div className='col-12 col-lg-4'>
              <img src='https://res.cloudinary.com/drjo0cbtf/image/upload/c_scale,f_auto,q_auto,w_800/v1650295066/bekome/magento2-magic-quadrant-gartner_ivy71p.png'
              alt="Magento 2 líder del mercado"
              className='img-fluid' />
          </div>
        </div>
      </div>
    </section>
  );
}
