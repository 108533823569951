import React from 'react';

export default function Intro() {
  return (
    <section className="content-section intro-section" id="start">
      <div className="container">
        <div className="row mt-lg-5">
          <div className="col-12">
            <h2 className="font-size__xl mb-5 text-secondary">
              Invierte en el presente, pensando en el futuro
            </h2>
            <p className="font-size__m">
              ¿Cuántas veces has querido incorporar <strong>nuevas funcionalidades</strong> a tu plataforma y te has encontrado con problemas
               e impedimentos?
            </p>
            <p className="font-size__m">
              No todas las tecnologías están preparadas para adaptarse a las diferentes fases o etapas de crecimiento de 
              tu negocio y eso, a la larga, genera problemas y gastos inesperados.
            </p>
            <p className="font-size__m">
              La nueva versión de Magento 2.x ha sido creada para <strong>revolucionar</strong> la manera en la que gestionamos el ecommerce. 
              Ofrece escalabilidad, un mantenimiento más sencillo y mejora enormemente la experiencia de usuario.
            </p>
            <p className="font-size__m">
              Incluye nuevas funcionalidades relacionadas con el <strong>marketing</strong> como el uso de cupones con códigos flexibles, 
              recomendación de productos vistos recientemente o recuperación de carritos abandonados, entre muchas otras.
            </p>
            <p className="font-size__m">
              Pero también ha mejorado las funciones administrativas con un nuevo panel que simplifica en gran medida el 
              trabajo de este departamento. Permite crear perfiles de usuario con diferentes permisos de acceso en función 
              del cargo, así como <strong>gestionar los pedidos</strong> de forma sencilla y rápida.
            </p>
            <p className="font-size__m">
              Además de incluir un nuevo checkout que agiliza la venta y aumenta la conversión.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
