import React from 'react';

export default function Beneficios() {
  return (
    <section
      className="content-section benefits-section bg-secondary"
      id="beneficios"
    >
      <div className="container-fluid text-white">
        <div className="row">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-size__xl text-primary">
              ¿Qué aporta Magento 2 a tu eCommerce?
            </h2>          
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-5 offset-lg-1'>
            <ul className='list-unstyled text-left font-size__m pl-5'>
              <li><i className='fa fa-check text-primary'></i>Escalable: múltiples idiomas, múltiples monedas, multi tienda.</li>
              <li><i className='fa fa-check text-primary'></i>Responsive: su diseño se adapta a todo tipo de dispositivos.</li>
              <li><i className='fa fa-check text-primary'></i>Mejora el SEO: estructura pensada para mejorar el posicionamiento en buscadores.</li>
              <li><i className='fa fa-check text-primary'></i>Rapidez: mejora la velocidad de carga.</li>
              <li><i className='fa fa-check text-primary'></i>Seguridad: proporciona una compra más segura.</li>
            </ul>
          </div>
          <div className='col-12 col-lg-5'>
            <ul className='list-unstyled text-left font-size__m pl-5'>
              <li><i className='fa fa-check text-primary'></i>Catálogo: infinidad de variantes y combinaciones de producto.</li>
              <li><i className='fa fa-check text-primary'></i>Personalización: además de múltiples extensiones permite crear desarrollos personalizados.</li>
              <li><i className='fa fa-check text-primary'></i>Mejora la UX: motor de búsqueda potente. Navegación por capas mediante filtros avanzados.</li>
              <li><i className='fa fa-check text-primary'></i>Mayor conversión: ofrece campañas y cupones descuento. Recupera carritos abandonados.</li>
              <li><i className='fa fa-check text-primary'></i>Gestión de contenidos: crea y gestiona contenidos mediante el page builder integrado.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
